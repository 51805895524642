.logo-icon {
  display: flex;
  width: auto;
  height: auto;
  line-height: 0;
  font-size: 0;
  & > svg {
    width:117px;
    height:40px;
  }
}

.page-header {
  width: 100%;
  height: 70px;
  background: #4c8de8;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10vh;

  &-head {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-welcome {
    font: normal normal bold 34px/42px Magallanes;
    letter-spacing: 0px;
    color: #fffffffa;
    opacity: 1;
  }

  &_group {
    display: flex;
    align-items: center;
    flex-direction: row;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    max-width: 250px;
    &_user {
      display: flex;
      flex-direction: row;
    }
  }

  &_name {
    margin-right: 15px;
    cursor: pointer;
  }

  &_logout {
    text-decoration: underline;
    cursor: pointer;
  }
}

@media screen and (max-width: 600px) {
  .logo-icon {
    &.logged-in {
      display: block;
      width: 44px;
      overflow: hidden;
    }
    & > svg {
      width:150px;
      height:54px;
    }
  }

  .page-header {
    height: 90px;
    margin-bottom: 3vh;

    &-head {
      &_center {
        justify-content: center;
      }
    }

    &_hide {
      display: none;
    }

    &_name {
      margin-right: 0;
    }

    &_group {
      flex-direction: column;
      align-items: end;
      gap: 10px;
      font-size: 14px;
      font-weight: 400;
    }

    &-welcome {
      display: none
    }
  }
}
